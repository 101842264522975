import React from "react";


import { isDesktop } from 'react-device-detect';


export class Jupiter extends React.Component {
    private async initJupiter () {
        //@ts-ignore
        window.Jupiter.init({
            containerClassName: 'p-0',
            endpoint: "https://thrumming-convincing-sanctuary.solana-mainnet.quiknode.pro/c05123a0ee430e3b06812a6e6a98be1fe600bf15/",
            formProps: {
              fixedOutputMint: true,
              initialOutputMint: "7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU",
            },
          });
          
    }
    render(): React.ReactNode {
        const buttonFormat = isDesktop ? 'button-download' : 'button-download-mobile';
        return (
            <button className={buttonFormat} onClick={this.initJupiter}>Buy SAMO</button>

        );

    }
}