export function cleanNumber(inputNumber: number, numDecimals?:number): string {
    // returns a - if value is 0
    if ( inputNumber === 0 ) {return '-'}

    //returns fixed number of decimals if <abs(100)
    else if(Math.abs(inputNumber) < 10){
        return inputNumber.toFixed(numDecimals);
    }
    else {
        let rounded = Math.round(inputNumber);
        return rounded.toLocaleString("en-US");
    }
}

export function cleanCurrency(inputNumber: number, currencySign: string, numDecimals?:number ): string {
    // returns a - if value is 0
    if ( inputNumber === 0 ) {return '-'}

    //returns fixed number of decimals if <abs(100)

    else if (Math.abs(inputNumber) < .01) {
        if(decimalCount(inputNumber) < 6) {
            return currencySign + inputNumber
        }
        else {return currencySign + inputNumber.toFixed(6);}
        
    }

    else if(Math.abs(inputNumber) < 10){
        return currencySign + inputNumber.toFixed(numDecimals);
    }
    else {
        let rounded = Math.round(inputNumber);
        return currencySign +  rounded.toLocaleString("en-US");
    }
}

export function cleanLargeCurrency(amount:number, currencySign: string): string {
    if (amount < 1000000) {
        return cleanCurrency(amount, currencySign);
    }
    else if (amount > 1000000000000) {    
        return currencySign  + (amount/1000000000000).toFixed(1) + ' Trillion'
    }
    else if (amount > 1000000000) {    
        return currencySign  + (amount/1000000000).toFixed(1) + ' Billion'
    }
    else if (amount >= 1000000) {    
        return currencySign  + (amount/1000000).toFixed(1) + ' Million'
    }
    else return currencySign  +  amount.toLocaleString("en-US");
}

const decimalCount = (num:number) => {
    // Convert to String
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes('.')) {
       return numStr.split('.')[1].length;
    };
    // String Does Not Contain Decimal
    return 0;
 }


 class Debounce {
    typingTimeout: null | ReturnType<typeof setTimeout>;
    debounce!: Debounce;
    constructor() {
      this.typingTimeout = null
      return this.debounce
    }
    debounceFcn = (callback:any, timeoutDuration = 900) => {
      if (!callback) {
        console.log('missing argument callback')
      }
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout)
      }
      this.typingTimeout = setTimeout(() => {
        callback()
      }, timeoutDuration)
    }
  }
  export const debounce = new Debounce()