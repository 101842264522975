import React from "react";
import '../css/statstable.css';
import '../css/statstable-mobile.css';
import { coinGeckoDogCoinApiUri, messariApiUri, tokenList } from "../constants/CoinStats";
import { cleanCurrency, cleanLargeCurrency, cleanNumber } from "../utils/helpers";
import { McapWidget } from "./McapWidget";
import { callCoinGeckoApi } from "../utils/coinmarketApi";
import { coinGeckoApi, MessariApi, MessariApiData } from "../types/apiTypes";
import { coinStats } from "../types/types";
import { isDesktop } from 'react-device-detect';
import html2canvas from 'html2canvas';
import { callMessariApi } from "../utils/messariApi";
import {createCanvas, loadImage} from 'canvas';
import gridBackground from '../img/gridback.png';

const devTesting = false;

interface State{
    tokenData: coinStats[];
}
interface Properties {}

export class StatsTable extends React.Component<Properties, State> {
    ref = React.createRef();
    constructor(props: Properties) {
        super(props);
        this.state = {tokenData: tokenList}
    }

    componentDidMount():void {
        if (!devTesting) {callCoinGeckoApi(coinGeckoDogCoinApiUri).then(res=>this.updateMarketData(res))}
        if (!devTesting) {callMessariApi(messariApiUri).then(res=>this.updateTransactionData(res))}
    }
    private updateMarketData(coinGeckoResults: coinGeckoApi[]):void {
        let currentTokenData: coinStats[] = this.state.tokenData;
        currentTokenData.forEach(token=>{
            let coinGeckoResult: coinGeckoApi | undefined = coinGeckoResults.find(result =>  {return result.symbol.toLowerCase() === token.symbol.toLowerCase()})
            if (coinGeckoResult) {
                token.marketCapValue = coinGeckoResult.market_cap;
                token.marketCapString = cleanLargeCurrency(coinGeckoResult.market_cap,'$');
                token.price = coinGeckoResult.current_price;
            }
        })
        this.setState({tokenData:currentTokenData});
        
    }
    private isSamo(tokenSymbol:string) {
        let highlight:string = '';
        if(tokenSymbol.toLowerCase()==='samo') {highlight = '-highlight'}
        return highlight;
    }
    private updateTransactionData(messariResults: MessariApi):void {
        let currentTokenData: coinStats[] = this.state.tokenData;
        let tokensArray: MessariApiData[] = messariResults.data;
        if(tokensArray !== undefined && messariResults !== undefined) {

            //Get Doge Tx Price
            let dogeTxFee:number | undefined = tokensArray.find(token=>{return token.symbol === 'DOGE'})!.metrics.on_chain_data.average_fee_usd;
            let doge:coinStats | undefined = currentTokenData.find(token => {return (token.symbol.toLowerCase() === 'doge')})
            if(dogeTxFee && doge) {
                doge.txCostUsd = dogeTxFee;
            }
            //Get Shiba Tx Price
            let shibTxFee:number | undefined = tokensArray.find(token=>{return token.symbol === 'ETH'})!.metrics.on_chain_data.average_fee_usd;
            let shib:coinStats | undefined = currentTokenData.find(token => {return (token.symbol.toLowerCase() === 'shib')})
            if(shibTxFee && shib) {
                shib.txCostUsd = shibTxFee;
            }
            
            //Get SOL Tx Fee
            const solTxCostInSol = 0.0000052;
            let solValUsd:number = tokensArray.find(token=>{return token.symbol === 'SOL'})!.metrics.market_data.price_usd;
            let sol:coinStats | undefined = currentTokenData.find(token => {return (token.symbol.toLowerCase() === 'sol')})
            if(solValUsd && sol) {
                sol.txCostUsd = solValUsd * solTxCostInSol;
            }
            this.setState({tokenData:currentTokenData});

        }
    }
    private buildTable(): React.ReactNode {
        let updatedList: coinStats[] = this.state.tokenData;
        let tableHeader: React.ReactNode = <></>;
        let tableBody: React.ReactNode = <></>;
        tableHeader = (
            <thead>
            <tr className="titles-row">
                <th></th>
                {updatedList.map(token=>{return (<th key={token.name} className={token.headerCss ? token.headerCss+' token-header-common' : ''}><img className='token-logo' alt={token.name + ' logo'} src={token.imgName}/><br/>{token.name}<br/>({token.symbol})</th>)})}
            </tr>
            </thead>
        );
         
        tableBody = (
            <tbody>
                <tr><td className="header-column">Blockchain</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-blockchain'}>{token.blockchain}</td>})}</tr>
                <tr><td className="header-column">Launch</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-launch'}>{token.launch}</td>})}</tr>
                <tr><td className="header-column">Market Cap</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-mcap'}>{token.marketCapString}</td>})}</tr>
                <tr><td className="header-column">Price</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-price'}>{cleanCurrency(token.price,'$',4)}</td>})}</tr>
                <tr><td className="header-column">Max Supply</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-supply'}>{token.maxSupplyString}</td>})}</tr>
                <tr><td className="header-column">Secured By</td>{updatedList.map(token=>{return <td className="data-cell" key={token.name+'-secured'}>{token.securedBy}</td>})}</tr>
                <tr><td className="header-column">Tx Cost</td>{updatedList.map(token=>{return <td className={'data-cell' + this.isSamo(token.symbol)} key={token.name+'-txcost'}>{cleanCurrency(token.txCostUsd,'$',2)}</td>})}</tr>
                <tr><td className="header-column">Tx Speed</td>{updatedList.map(token=>{return <td className={'data-cell' + this.isSamo(token.symbol)} key={token.name+'-speed'}>{token.txSpeedSec} sec</td>})}</tr>
                <tr><td className="header-column">Energy/Tx <span className="joules">(Joules)*</span></td>{updatedList.map(token=>{return <td className={'data-cell' + this.isSamo(token.symbol)}key={token.name+'-energy'}>{cleanNumber(token.energyPerTxJoules)} J</td>})}</tr>
            </tbody>
        );

        return <table id="StatsTable">{tableHeader}{tableBody}</table>
    }

    private buildMobileTable(): React.ReactNode {
        let updatedList: coinStats[] = this.state.tokenData;
        let tableHeader: React.ReactNode = <></>;
        let tableBody: React.ReactNode = <></>;
        tableHeader = (
            <thead>
            <tr className="titles-row-mobile">
                {updatedList.map(token=>{return (<th key={token.name} className={token.headerCss ? token.headerCss+'-mobile token-header-common-mobile' : ''}><img className='token-logo-mobile' alt={token.name + ' logo'} src={token.imgName}/><br/>{token.name}<br/>({token.symbol})</th>)})}
            </tr>
            </thead>
        );
         
        tableBody = (
            <tbody>
                <tr className="header-row-mobile" ><td colSpan={3}>Blockchain</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-blockchain'}>{token.blockchain}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Launch</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-launch'}>{token.launch}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Market Cap</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-mcap'}>{token.marketCapString}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Price</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-price'}>{cleanCurrency(token.price,'$',4)}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Max Supply</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-supply'}>{token.maxSupplyString}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Secured By</td></tr>
                <tr>{updatedList.map(token=>{return <td className="data-cell-mobile" key={token.name+'-secured'}>{token.securedBy}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Tx Cost</td></tr>
                <tr>{updatedList.map(token=>{return <td className={'data-cell-mobile' + this.isSamo(token.symbol)} key={token.name+'-txcost'}>{cleanCurrency(token.txCostUsd,'$',2)}</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Tx Speed</td></tr>
                <tr>{updatedList.map(token=>{return <td className={'data-cell-mobile' + this.isSamo(token.symbol)}>{token.txSpeedSec} sec</td>})}</tr>
                <tr className="header-row-mobile" ><td colSpan={3}>Energy per Tx (Joules)*</td></tr>
                <tr>{updatedList.map(token=>{return <td className={'data-cell-mobile' + this.isSamo(token.symbol)}>{cleanNumber(token.energyPerTxJoules)} J</td>})}</tr>
            </tbody>
        );

        return <table id="StatsTableMobile">{tableHeader}{tableBody}</table>
    }
 
    private async generateImage(): Promise<void> {
        let gridCanvasImg = await loadImage(gridBackground);
        //modified from: https://codesandbox.io/s/react-download-screenshot-qmk4g?from-embed=&file=/src/ScreenCapture.js
        //and https://stackoverflow.com/questions/14595541/capture-div-into-image-using-html2canvas
        html2canvas(document.getElementById('toPrint') as HTMLElement, { backgroundColor:null } )
            .then(tableCanvas => {    
                var background = new Image();
                background.src = '../img/moonback.png'
                const marginTop = isDesktop ? 0.1 : 0; //10% 
                const marginBottom = isDesktop ? 25 : 100;
                const tableHeight = (tableCanvas.height*(1+2*marginTop))+marginBottom;
                const tableWidth = tableCanvas.width;
                const backgroundWidth = gridCanvasImg.naturalWidth;
                const backgroundHeight = gridCanvasImg.naturalHeight;
                const scale = Math.max(tableWidth / backgroundWidth, tableHeight / backgroundHeight);
                const x = (tableWidth / 2) - (backgroundWidth / 2) * scale;
                const y = (tableHeight / 2) - (backgroundHeight / 2) * scale;
                const newCanvas = createCanvas(tableWidth , tableHeight); 
                const backgroundCanvas = newCanvas.getContext('2d');
                backgroundCanvas.drawImage(gridCanvasImg, x,y, backgroundWidth*scale,backgroundHeight*scale);
                const topGap = isDesktop ? tableHeight/(10+(10*marginTop)) : 10;
                backgroundCanvas.drawImage(tableCanvas, 0, topGap);
                backgroundCanvas.font = ' 36px Flick';
                backgroundCanvas.fillStyle = 'rgba(255,255,255,1)'
                backgroundCanvas.fillText(`DOGCOINCOMPARISON.COM`, 56, tableHeight-50)
                const a = document.createElement("a");
                var imageURL = backgroundCanvas.canvas.toDataURL("image/png");
                a.href = imageURL;
                a.download = "samoChart.png";
                a.click();


            }
        );


        
    }
    render(): React.ReactNode {
        const buttonFormat = isDesktop ? 'button-download' : 'button-download-mobile';
        const footnoteFormat = isDesktop ? 'footnote' : 'footnote-mobile'; 
        return (
        <div className="main" ref={this.ref as React.RefObject<HTMLDivElement>}>
            <div id="toPrint">
                {isDesktop ? this.buildTable() : this.buildMobileTable()}
                <div className={footnoteFormat}>*2 Google Searches = 2,160 Joules</div>
                <br/>
                <McapWidget dogCoinStats={this.state.tokenData}/>
            </div>
            <button className={buttonFormat} onClick={this.generateImage}>Download Chart</button>

            <div >     
            </div>

        </div>
        );

    }
}