import axios from "axios";
import { coinGeckoApi } from "../types/apiTypes";

interface AxiosResponse {
    status: number;
    data: coinGeckoApi[];
}
export async function callCoinGeckoApi (uri: string):Promise<coinGeckoApi[]> {
    console.log('Updating token pricing data . . . ')
    try {
        const response: AxiosResponse = await axios.get(uri, 
            {
                timeout: 10000, 
                timeoutErrorMessage: 'coingecko call timeout'
            }
        );
        
        if (response.status === 200) {
            console.log('Token pricing data successfully updated.')
            return response.data;

        } else {
            throw new Error(`Unrecognized non-error response ${JSON.stringify(response)}`);
        }
    }
    catch (error: any) {
        throw new Error("Issues getting CoinGecko Response.");
    }

}
