import React from "react";
import '../css/mcapwidget.css';
import samologo from '../img/samo_circle_med.png'
import { coinStats } from "../types/types";
import { cleanCurrency, cleanNumber } from "../utils/helpers";
import { isDesktop } from 'react-device-detect';


interface State{
    token: CompareTokens;
    mcapComparison: DisplayValues;
    showMenu: boolean;
}
interface Properties {
    dogCoinStats: coinStats[];
}
interface DisplayValues {
    finalPrice: string,
    percentIncr: string,
}





type CompareTokens = 'Dogecoin' | 'Shiba Inu';

export class McapWidget extends React.Component<Properties, State> {
    constructor(props: Properties) {
        super(props);
        this.state = {token: 'Dogecoin', mcapComparison: {finalPrice: '', percentIncr: ''}, showMenu: false}
    }

    componentDidMount():void {
        this.updateComparisonValues(this.state.token);

    }
    componentDidUpdate(prevProps:Properties):void {
        console.log('did update' )
        if(prevProps !== this.props){
            this.updateComparisonValues(this.state.token);
        }
    }

        // Toggles the dropdown menu visibility
        toggleDropdown = () => {
            this.setState(prevState => ({
                ...prevState,
                showMenu: !prevState.showMenu // Toggle the state
            }));
        }
    

    /**
     * 
     * @param selectedToken either Doge or Shiba
     * Method will update state values that are displayed for price and percent increase. 
     */
    private updateComparisonValues(selectedToken:CompareTokens):void {

        const comparisonToken: CompareTokens = selectedToken;
        const comparisonTokenData: coinStats | undefined = this.props.dogCoinStats.find(token=> {return (token.name===comparisonToken)})
        const samoTokenData: coinStats | undefined =  this.props.dogCoinStats.find(token=> {return (token.name==='Samoyedcoin')})
        let finalPrice:number = 0;
        let percentIncr: number = 0;
        if (comparisonTokenData && samoTokenData) {
            percentIncr = (comparisonTokenData.marketCapValue/samoTokenData.marketCapValue);
            finalPrice = percentIncr * samoTokenData.price;
        }
        let newDisplayedValues: DisplayValues =  {
            finalPrice: cleanCurrency(finalPrice,'$',2),
            percentIncr: ' (⇡'+ cleanNumber(percentIncr,0) + 'x)'
        }
        this.setState({ mcapComparison: newDisplayedValues, token: selectedToken, showMenu: false });

    }


    render(): React.ReactNode {
        const selectedToken = this.state.token === 'Dogecoin' ? 'DOGE' : 'SHIB';
        const divStyle = isDesktop ? 'McapWidget' : 'McapWidgetMobile';
        const percentStyle = isDesktop ? 'percent-increase' : 'percent-increase-mobile';
        const dollarStyle = isDesktop ? 'dollar-amt' : 'dollar-amt-mobile'
        const samo = this.props.dogCoinStats.find(token=>{return (token.symbol.toLowerCase() === 'samo')})
        const samoPriceDisplay = samo ? 'Current SAMO Price: ' + cleanCurrency(samo.price,'$',3) : '' ; 
        
        return (
            <div id={divStyle}>
                <span className="mcap-of">SAMO With the Market Cap of {selectedToken}</span> 
                <div className="stats-line">
                    <img src={samologo} alt='samo-logo' className='logo'/>
                    <span className={dollarStyle + ' bold'}>{this.state.mcapComparison.finalPrice} &nbsp;</span>
                    <span className={percentStyle + ' bold'}> {this.state.mcapComparison.percentIncr}</span>
                </div>
                <div className='samo-price'>{samoPriceDisplay}</div>
                <div className="dropdown-link">
                <button className="toggle-button" onClick={this.toggleDropdown}>
                    Select token to compare
                </button>

                {this.state.showMenu && (
                    <ul className="toggle-menu">
                        <li className="dropdown-item" onClick={() => this.updateComparisonValues('Dogecoin')}>Dogecoin</li>
                        <li className="dropdown-item" onClick={() => this.updateComparisonValues('Shiba Inu')}>Shiba Inu</li>
                    </ul>
                )}
            </div>

            </div>
            );

    }
}

/* export class McapWidgetAlt extends React.Component<Properties, State> {
    constructor(props: Properties) {
        super(props);
        this.state = {token: 'Dogecoin', mcapComparison: {finalPrice: '', percentIncr: ''}}
    }

    componentDidMount():void {
    }
    componentDidUpdate(prevProps:Properties):void {
        if(prevProps !== this.props){
            this.updateComparisonValues(this.state.token);
        }
        
    }

    private updateComparisonValues(selectedToken:CompareTokens):void {
        const comparisonToken: CompareTokens = selectedToken;
        const comparisonTokenData: coinStats | undefined = this.props.dogCoinStats.find(token=> {return (token.name===comparisonToken)})
        const samoTokenData: coinStats | undefined =  this.props.dogCoinStats.find(token=> {return (token.name==='Samoyedcoin')})
        let finalPrice:number = 0;
        let percentIncr: number = 0;
        if (comparisonTokenData && samoTokenData) {
            percentIncr = (comparisonTokenData.marketCapValue/samoTokenData.marketCapValue);
            finalPrice = percentIncr * samoTokenData.price;
        }
        let newDisplayedValues: DisplayValues =  {
            finalPrice: cleanCurrency(finalPrice,'$',2),
            percentIncr: ' (⇡'+ cleanNumber(percentIncr,0) + 'x)'
        }
        this.setState({mcapComparison: newDisplayedValues, token:selectedToken});
    }

    private getDisplayValue(selectedToken:CompareTokens):DisplayValues {
        const tokenData: coinStats | undefined = selectedToken === 'Dogecoin' ? 
            this.props.dogCoinStats.find(token=> {return (token.symbol.toLowerCase()==='doge')}) :
            this.props.dogCoinStats.find(token=> {return (token.symbol.toLowerCase()==='shib')});
        const samoTokenData: coinStats | undefined =  this.props.dogCoinStats.find(token=> {return (token.name==='Samoyedcoin')})
        let finalPrice:number = 0;
        let percentIncr: number = 0;
        if (tokenData && samoTokenData) {
            percentIncr = (tokenData.marketCapValue/samoTokenData.marketCapValue);
            finalPrice = percentIncr * samoTokenData.price;
        }
        let newDisplayedValues: DisplayValues =  {
            finalPrice: cleanCurrency(finalPrice,'$',2),
            percentIncr: ' (⇡'+ cleanNumber(percentIncr,0) + 'x)'
        }
        return newDisplayedValues;
    }


    render(): React.ReactNode {
        const divStyle = isDesktop ? 'McapWidget' : 'McapWidgetMobile';
        const percentStyle = isDesktop ? 'percent-increase' : 'percent-increase-mobile';
        const dollarStyle = isDesktop ? 'dollar-amt' : 'dollar-amt-mobile'
        const samo = this.props.dogCoinStats.find(token=>{return (token.symbol.toLowerCase() === 'samo')})
        const samoPriceDisplay = samo ? 'Current Price: ' + cleanCurrency(samo.price,'$',3) : '' ; 

        return (<div className='mcap-container'>
            <div id={divStyle}>
                <span className="mcap-of">SAMO With the Market Cap of 'DOGE'</span>                
                <div className="stats-line">
                    <img src={samologo} alt='samo-logo' className='logo'/>
                    <span className={dollarStyle + ' bold'}>{this.getDisplayValue('Dogecoin').finalPrice} &nbsp;</span>
                    <span className={percentStyle + ' bold'}> {this.getDisplayValue('Dogecoin').percentIncr}</span>
                </div>
                <div className='samo-price'>{samoPriceDisplay}</div>

            </div>
            <div id={divStyle}>
            <span className="mcap-of">SAMO With the Market Cap of 'SHIB'</span>                
                <div className="stats-line">
                    <img src={samologo} alt='samo-logo' className='logo'/>
                    <span className={dollarStyle + ' bold'}>{this.getDisplayValue('Shiba Inu').finalPrice} &nbsp;</span>
                    <span className={percentStyle + ' bold'}> {this.getDisplayValue('Shiba Inu').percentIncr}</span>
                </div>
                <div className='samo-price'>{samoPriceDisplay}</div>
            </div>
            </div>
            );

    }
} */