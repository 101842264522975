import { coinStats } from "../types/types";
import samologo from '../img/samo_circle_med.png';
import shibalogo from '../img/shiba_logo.png';
import dogelogo from '../img/doge_logo.png';

export const coinGeckoDogCoinApiUri:string = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=samoyedcoin%2C%20dogecoin%2C%20shiba-inu&order=market_cap_desc&per_page=100&page=1&sparkline=false';
export const messariApiUri:string='https://data.messari.io/api/v2/assets';

export let dogeCoin:coinStats = {
    name: 'Dogecoin',
    symbol: 'DOGE',
    blockchain: 'Dogecoin',
    launch: '2013',
    marketCapString: '$19 Billion',
    marketCapValue: 19000000000,
    maxSupplyString: 'Unlimited',
    maxSupplyValue: Infinity,
    securedBy: 'Proof-of-Work',
    txCostUsd: 0.30,
    txSpeedSec: 60,
    energyPerTxJoules: 430000,
    headerCss: 'doge-header',
    price: 0.1424,
    imgName: dogelogo
};

export let shibaInu:coinStats = {
    name: 'Shiba Inu',
    symbol: 'SHIB',
    blockchain: 'Ethereum',
    launch: '2020',
    marketCapString: '$14.6 Billion',
    marketCapValue: 14600000000,
    maxSupplyString: '546 Trillion',
    maxSupplyValue: 546000000000000,
    securedBy: 'Proof-of-Stake',
    txCostUsd: 15,
    txSpeedSec: 15,
    energyPerTxJoules: 144036,
    headerCss: 'shiba-header',
    price: 0.00002527,
    imgName: shibalogo
};

export let samo:coinStats = {
    name: 'Samoyedcoin',
    symbol: 'SAMO',
    blockchain: 'Solana',
    launch: '2021',
    marketCapString: '$62 Million',
    marketCapValue: 62000000,
    maxSupplyString: '4.75 Billion',
    maxSupplyValue: 4750000000,
    securedBy: 'Proof-of-Stake / History',
    txCostUsd: 0.0005,
    txSpeedSec: 0.8,
    energyPerTxJoules: 3290,
    headerCss: 'samo-header',
    price: 0.019,
    imgName: samologo
};

export const tokenList: coinStats[] = [dogeCoin,shibaInu,samo];