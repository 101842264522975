import React from 'react';
import { HeaderBar } from './components/HeaderBar';
import { StatsTable } from './components/StatsTable';
import { Jupiter } from './components/Jupiter';
import './App.css';
import { SocialIcons } from './components/SocialIcons';
import { Footer } from './components/Footer';





class App extends React.Component {
    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "https://terminal.jup.ag/main-v2.js";
        script.async = true;
    
        document.body.appendChild(script);

    }
    render(): React.ReactNode {

        
        return (
        <div className="App">

            <HeaderBar/>
            <StatsTable/>
            <Jupiter/>
            <SocialIcons/>
            <Footer/>
        </div>
        );
    }
}

export default App;
