import React from "react";
import '../css/headerfooter.css';
import { isDesktop } from 'react-device-detect';


export class HeaderBar extends React.Component {
 


    render(): React.ReactNode {
        const headerCss: string = isDesktop ? 'header' : 'header-mobile' ; 
        
        return (
        <div className={headerCss}>
            <span className="logo">Dog Coin Stats</span>
        </div>
        );

    }
}