import React from "react";
import '../css/headerfooter.css';
import { isDesktop } from 'react-device-detect';


export class Footer extends React.Component {
 


    render(): React.ReactNode {
        const headerCss: string = isDesktop ? 'rights-reserved' : 'rights-reserved-mobile' ; 
        
        return (
        <div className={headerCss}>
            <span className="logo">© 2023 All Rights Reserved.</span>
        </div>
        );

    }
}