import axios from "axios";
import { MessariApi } from "../types/apiTypes";


export async function callMessariApi (uri: string):Promise<MessariApi> {
    console.log('Updating transaction cost data . . . ')
    try {
        const response = await axios.get(uri, 
            {
                timeout: 10000, 
                timeoutErrorMessage: 'messari call timeout'
            }
        );
        
        if (response.status === 200) {
            console.log('Transaction data successfully updated.')
            return response.data;

        } else {
            throw new Error(`Unrecognized non-error response ${JSON.stringify(response)}`);
        }
    }
    catch (error: any) {
        throw new Error("Issues getting CoinGecko Response.");
    }

}
